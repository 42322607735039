import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import prettier from 'prettier/standalone';
import parserBabel from 'prettier/parser-babel';
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

@Component({
  components: {},
})
export default class ChatRecordTool extends Vue {

  chatMessageRecordHandlerResult = '';

  currentSelectChangePictureSenderId = 0;

  selectFiles = [];

  selectChangeSenderPictureFiles: any = [];

  recordData: any = {};

  senderMap = new Map();

  senderPicture = new Map();

  contentStyleData = new Array();

  senderPictureCardLoading = false;

  readingMode = {
    enable: false,
    md: '6',
    icon: 'mdi-eye-outline'
  };


  switchButton = {
    showSenderId: true,
    showDate: true
  }

  recordInfo = {
    recordSize: 0,
    senderSize: 0
  }

  tabsTextFrameData = {
    name: 'Context风格'
  }

  textContext = {
    source: ''
  }

  changeFile() {
    const _this = this;
    const reader = new FileReader();
    reader.onload = function (e: any) {
      _this.recordData = JSON.parse(e.target.result);
      _this.chatRecordTextViewHandler();
    }
    reader.readAsText(_this.selectFiles[0]);

  }

  switchReadingMode() {
    if (this.readingMode.enable) {
      this.readingMode.enable = false;
      this.readingMode.md = '6';
      this.readingMode.icon = 'mdi-eye-outline'
    } else {
      this.readingMode.enable = true;
      this.readingMode.md = '12';
      this.readingMode.icon = 'mdi-eye-off-outline';
    }
  }

  chatRecordTextViewHandler() {
    let result = '';
    const _this = this;
    this.recordData.records.map((value: any, index: number) => {
      if (_this.senderMap.get(value.senderId) === undefined) {
        _this.senderMap.set(value.senderId, value.senderName);
        _this.senderPicture.set(value.senderId, 'https://cdn.vuetifyjs.com/images/lists/' + _this.senderMap.size + '.jpg');
      }
      result += `${value.senderName}`;
      if (_this.switchButton.showSenderId) {
        result += ` (${value.senderId})`;
      }
      if (_this.switchButton.showDate) {
        result += ` ${value.date}`;
      }
      result += "\n";
      result += `${value.content} \n\n`
    });

    this.recordInfo.recordSize = this.recordData.records.length;
    this.recordInfo.senderSize = this.senderMap.size;
    this.chatMessageRecordHandlerResult = result;
  }


  @Watch('switchButton.showSenderId')
  @Watch('switchButton.showDate')
  switchButtonValueChange() {
    this.chatRecordTextViewHandler();
  }


  getFormatSourceJsonText() {
    this.textContext.source = prettier.format(JSON.stringify(this.recordData), {
      parser: "json",
      plugins: [parserBabel],
      endOfLine: 'auto'
    });
  }

  contentStyleFrameRecordTextHandler() {
    const _this = this;
    _this.contentStyleData = [];
    this.recordData.records.map((value: any, index: number) => {
      _this.contentStyleData.push({
        avatar: _this.senderPicture.get(value.senderId),
        title: value.senderName,
        content: value.content
      });
    });
  }

  selectChangePictureSender(id: number) {
    this.currentSelectChangePictureSenderId = id;
  }


  changeSenderPictureFile() {
    const _this = this;
    const reader = new FileReader();
    reader.onload = function (e: any) {
      _this.senderPicture.set(_this.currentSelectChangePictureSenderId, e.target.result);
    }
    reader.readAsDataURL(_this.selectChangeSenderPictureFiles);
    this.senderPictureCardLoading = true;
    const timer = setInterval(function () {
      _this.senderPictureCardLoading = false;
      clearInterval(timer);
    }, 1000);
  }


  exportImage() {
    html2canvas(this.$refs.imageDom as any).then(canvas => {
      // 转成图片，生成图片地址
      const imgUrl = canvas.toDataURL("image/png");

      const contentWidth = canvas.width;
      const contentHeight = canvas.height;
      //一页pdf显示html页面生成的canvas高度;
      const pageHeight = contentWidth / 592.28 * 841.89;
      //未生成pdf的html页面高度
      let leftHeight = contentHeight;
      //页面偏移
      let position = 0;
      //a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
      const imgWidth = 595.28;
      const imgHeight = 592.28 / contentWidth * contentHeight;
      const pageData = canvas.toDataURL('image/jpeg', 1.0);
      //注①
      const pdf = new jsPDF('portrait', 'pt', 'a4');
      //有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
      //当内容未超过pdf一页显示的范围，无需分页
      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          //arg3-->距离左边距;arg4-->距离上边距;arg5-->宽度;arg6-->高度
          pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
          leftHeight -= pageHeight;
          position -= 841.89;
          //避免添加空白页
          if (leftHeight > 0) {
            //注②
            pdf.addPage();
          }
        }
      }

      pdf.save('name.pdf');

    });


  }



}